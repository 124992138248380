import {
  Logout,
  ProductionQuantityLimits,
  AccountBalance,
  Storefront,
} from "@mui/icons-material";
import { SidebarItemsType } from "../../types/sidebar";

import {
  PieChart,
  Calendar,
  CreditCard,
  Layers,
  Gift,
  Users,
  Settings,
  LogOut,
} from "react-feather";
import { Link } from "react-router-dom";

const allRoles = [
  "ROLE_ADMIN",
  "ROLE_PROMOTER",
  "ROLE_VENDOR",
  "ROLE_VENDOR_SUPERVISOR",
  "ROLE_MERCHANT",
  "ROLE_MERCHANT_REORDER",
];

const vendorTeamRoles = ["ROLE_VENDOR", "ROLE_VENDOR_SUPERVISOR"];

const pagesSection = [
  {
    href: "/dashboard",
    icon: PieChart,
    title: "Dashboard",
    roles: [...allRoles],
  },
  {
    href: "/events",
    icon: Calendar,
    title: "Events",
    roles: ["ROLE_ADMIN", "ROLE_PROMOTER", ...vendorTeamRoles],
    children: [
      {
        href: "/events/add-event",
        title: "Add New",
        roles: ["ROLE_ADMIN", "ROLE_PROMOTER"],
      },
      {
        href: "/events",
        title: "Search",
        roles: ["ROLE_ADMIN", "ROLE_PROMOTER", ...vendorTeamRoles],
      },
      // {
      //   href: "/events/merchant-allocation",
      //   title: "Merchants",
      //   roles: ["ROLE_ADMIN", ...vendorTeamRoles],
      // },
      // {
      //   href: "/events/stations",
      //   title: "Stations",
      //   roles: ["ROLE_ADMIN", ...vendorTeamRoles],
      // },
      {
        href: "/events/venues",
        title: "Venues",
        roles: ["ROLE_ADMIN", "ROLE_PROMOTER"],
      },
      {
        href: "/events/country-tours",
        title: "Country Tours",
        roles: ["ROLE_ADMIN", "ROLE_PROMOTER"],
      },
      {
        href: "/events/world-tours",
        title: "World Tours",
        roles: ["ROLE_ADMIN", "ROLE_PROMOTER"],
      },
      {
        href: "/events/vendors",
        title: "Vendors",
        roles: ["ROLE_ADMIN", "ROLE_PROMOTER"],
      },
      {
        href: "/events/promoters",
        title: "Promoters",
        roles: ["ROLE_ADMIN"],
      },
    ],
  },
  {
    href: "/products",
    icon: Gift,
    title: "Products",
    roles: ["ROLE_ADMIN", ...vendorTeamRoles],
    children: [
      {
        href: "/products/create",
        title: "Add New",
        roles: ["ROLE_ADMIN", ...vendorTeamRoles],
      },
      {
        href: "/products/search",
        title: "Search",
        roles: ["ROLE_ADMIN", ...vendorTeamRoles],
      },
      {
        href: "/products/multiple-allocation",
        title: "Allocations",
        roles: ["ROLE_ADMIN", ...vendorTeamRoles],
      },
    ],
  },
  {
    href: "/stock",
    icon: ProductionQuantityLimits,
    title: "Stock",
    roles: ["ROLE_ADMIN", ...vendorTeamRoles],
    children: [
      {
        href: "/stock/list",
        title: "List",
        roles: ["ROLE_ADMIN", ...vendorTeamRoles],
      },
      {
        href: "/stock/transfer",
        title: "Transfer",
        roles: ["ROLE_ADMIN", ...vendorTeamRoles],
      },
      {
        href: "/stock/reconcile",
        title: "Reconcile",
        roles: ["ROLE_ADMIN", ...vendorTeamRoles],
      },
    ],
  },
  {
    href: "/finance",
    icon: AccountBalance,
    title: "Finance",
    roles: ["ROLE_ADMIN", ...vendorTeamRoles],
    children: [
      {
        href: "/finance/bank-statement-upload",
        title: "Bank Statement Upload",
        roles: ["ROLE_ADMIN", ...vendorTeamRoles],
      },
      {
        href: "/finance/recon",
        title: "Bank Reconcile",
        roles: ["ROLE_ADMIN", ...vendorTeamRoles],
      },
      {
        href: "/finance/sales",
        title: "Sales",
        roles: ["ROLE_ADMIN", ...vendorTeamRoles],
      },
      {
        href: "/finance/re-orders",
        title: "Re-Orders",
        roles: ["ROLE_ADMIN", ...vendorTeamRoles],
      },
      {
        href: "/finance/commission",
        title: "Commission",
        roles: ["ROLE_ADMIN"],
      },
    ],
  },

  {
    href: "/iqretail",
    icon: Storefront,
    title: "IQRetail",
    roles: [...allRoles],
    children: [
      {
        href: "/iqretail/import",
        title: "Import",
        roles: ["ROLE_ADMIN", ...vendorTeamRoles],
      },
      {
        href: "/iqretail/export",
        title: "Export",
        roles: ["ROLE_ADMIN", ...vendorTeamRoles],
      },
      {
        href: "/iqretail/txreverse",
        title: "Reverse",
        roles: ["ROLE_ADMIN", ...vendorTeamRoles],
      },
    ],
  },

  {
    href: "/users",
    icon: Users,
    title: "Users",
    roles: [...allRoles],
    children: [
      {
        href: "/users/add-user",
        title: "Add New",
        roles: ["ROLE_ADMIN", ...vendorTeamRoles],
      },
      {
        href: "/users/import-users",
        title: "Import",
        roles: ["ROLE_ADMIN", ...vendorTeamRoles],
      },
      {
        href: "/users",
        title: "Search",
        roles: ["ROLE_ADMIN", ...vendorTeamRoles],
      },
    ],
  },
  {
    href: "/settings/search",
    icon: Settings,
    title: "Settings",
    roles: [...allRoles],
  },
  {
    href: "/logout",
    icon: Logout,
    title: "Sign out",
    roles: [...allRoles],
  },
] as SidebarItemsType[];

const filterPagesByRole = (role: string) => {
  const userMenu = pagesSection.filter((p: SidebarItemsType) =>
    p.roles?.includes(role)
  );
  userMenu.forEach((menu: SidebarItemsType) => {
    menu.children = menu.children?.filter((ch) => ch.roles?.includes(role));
  });

  return userMenu;
};

const getNavItems = (role: string) => {
  return [
    {
      title: "",
      pages: role ? filterPagesByRole(role) : pagesSection,
    },
  ];
};

export default getNavItems;
